import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const JoinKBK = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    email: '',
  });

  const [error, setError] = useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Basic form validation
    if (!formData.email) {
      setError('Email address is required.');
      return;
    }

    // Perform your form submission logic here
    emailjs.sendForm('service_ap5okg8', 'template_rvp8n6n', form.current, 'FcHxBdVjVdXngu5xC')
      .then((result) => {
        alert("Subscribed Successfully");

        // Reset form fields
        setFormData({ email: '' });
      })
      .catch((error) => {
        alert("Error! Please try again.");
      });
  };

  const handleInputChange = (e) => {
    // Update the form data as the user types
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Clear previous error message
    setError(null);
  };
  const sectionStyle = {
    backgroundImage: 'url(https://res.cloudinary.com/dlmscrvp7/image/upload/v1703663289/sub_mhvb0k.jpg)',
    // Add other necessary background styles here
  };

  return (
    <div>
      <section
        className="join-one jarallax"
        data-jarallax=""
        data-speed="0.2"
        data-imgposition="50% 0%"
        style={sectionStyle} // Apply the background image via inline style
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="join-one__inner">
                <div className="join-one__icon">
                  <span className="icon-helping-hand"></span>
                </div>
                <h2>Join KBK Welfare Association Group</h2>
              </div>
            </div>
          </div>
        </div>
       
      </section>
      <section className="newsletter-one">
          <div className="container">
            <div className="newsletter-one__inner">
              <div className='row'>
                <div className="col-xl-3">
                    <div className="newsletter-one__left">
                        <div className="newsletter-one__subscriber-box">
                            <div className="icon">
                                <span className="icon-news"></span>
                            </div>
                            <div className="text">
                                <p>Subscribe</p>
                                <h4>Newsletter</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                  <div className="newsletter-one__right">
                    <form autoComplete='off' ref={form} onSubmit={handleFormSubmit}>
                      <div className="col-md-8 form-group mb-4">
                        <input
                          value={formData.email}
                          onChange={handleInputChange}
                          type="email"
                          name='email'
                          placeholder='Enter Your Email Address*'
                          className="form-control emailsubsciber"
                          required
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                      </div>
                      <div className="col-md-6 mpy-5">
                        <button type='submit' value="Send" className="btn btn-primary px-5 mx-auto text-center">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-xl-3">
                    <div className="newsletter-one__left">
                        <div className="newsletter-one__subscriber-box">
                            <div className="icon">
                                <span className="icon-news"></span>
                            </div>
                            <div className="text">
                                <p>Join</p>
                                <h4>Our Team</h4>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

           </div>
        </div>
        
      </section>
    </div>
  );
};

export default JoinKBK;
