import React from "react";
export default function AboutSection() {
    return (
        <div>
            <section className="welcome-one wel-cm">
                <div className="welcome-one-hands"></div>
                <div className="container">
                    <br />
                    <br />

                    <div className="row align-items-center py-5">

                        <div className="col-xl-4 col-lg-4">
                            <div className="welcome-one__left">
                                <div className="welcome-one__img wow slideInLeft animated animated" data-wow-delay="100ms">
                                    <img className="img-gg" src="images/welcome_one_img_1.jpg" alt="icon" />
                                    <div className="welcome-one__badge">
                                        <img src="images/about-banner.jpg" alt="Orphans welfare" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8">
                            <div className="welcome-one__right">
                                <div className="block-title text-left">
                                    <h4>Helping Today</h4>
                                    <h1>About KBK WELFARE ASSOCIATION</h1>
                                </div>
                                <p className="welcome-one__text">
                                    The Hindu scripture Bhagwad Gita says - Strive constantly to serve the welfare of the world; by devotion to selfless work one attains the supreme goal of life. Do your work with the welfare of others
                                    always in mind.
                                </p>

                                <p className="pg-an ab">
                                    <b> Another Sanskrit sloka in Gita says </b>
                                    <br />

                                    <span className="sl-clr">
                                        datavyam iti yad danam <br />
                                        diyate ’nupakarine <br />
                                        dese kale ca patre ca <br />
                                        tad danam sattvikam smrtam
                                    </span>
                                </p>

                                
                               
                            </div>
                        </div>
                        <div className="col-md-6">
                             <h5 className="pg-an">Which means -</h5>
                            <p className="pg-an">Charity given out of duty, without expectation of return, at the proper time and place, and to a worthy person is considered to be in the mode of goodness.</p>

                            <p className="pg-an">
                                Not just limited to sharing food and clothing, but KBK Non-Government Organization aims to contribute to emergency services when natural disasters occur in the steep regions of Telangana.
                            </p>
                            <p className="pg-an">We are very passionate about serving and reaching out to those who are in dire need of basic necessities and sometimes even more.</p>
                            <p className="pg-an">We strongly believe in the Sanskrit quote</p>
                            <p className="pg-an">“Paropakarartham Idam Sariram”</p>
                        </div>
                        <div className="col-md-6">
                        <h5 className="pg-an"> Meaning </h5>
                                <p className="pg-an">“Human body is meant to do good to others”</p>
                                <p className="pg-an">
                                    With an exceedingly good future layout of plans for community service, KBK Non-Government Organization is putting all efforts to work persistently for the impoverished and serve them at a time
                                    whenever there is more than a need.
                                </p>
                                <p className="pg-an">
                                    KBK Welfare Association devotedly commits its services to keep this flame of charity mission outgoing always and we feel very excited about our contribution, participation and support extended
                                    by his staff, teams, volunteers, partners and many others.
                                </p>
                        </div>
                       
                    </div>
                </div>
            </section>
        </div>
    );
}
