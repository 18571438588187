import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function ClothsDistribution() {
  return (
    <div>
        <Helmet>
        <title>Cloth Donation in Hyderabad | Non-Government Organization</title>

        <meta name="Description" content="KBK welfare association has actively worked in cloth donation in hyderabad and widely distributed raincoats and blankets to many needy people."/>

        <meta name="Keywords" content="cloth Donation, Clothes Donation, NGO Organizations, Grocery Donation, KBK Welfare work, Famous NGOs in Hyderabad"/>

        <meta http-equiv="cleartype" content="on"/>

        <meta name="author" content="Kbkwelfare"/>

        <meta name=" https://kbkwelfareassociation.com/cloth-distribution.html" CONTENT="Clothes Donation,  Cloth Donation"/>

        <meta name="rating" CONTENT="General"/>

        <meta name="robots" content="index,follow,noarchive"/>

        <meta name="revisit-after" CONTENT="daily"/>

        <meta content="English" name="language"/>


        <meta property="og:type" content="website"/>

        <meta property="og:locale" content="en_US"/>

        <meta property="og:title" content="Cloth Donation in Hyderabad | Non-Government Organization"/>

        <meta property="og:description" content="KBK welfare association has actively worked in cloth donation in hyderabad and widely distributed raincoats and blankets to many needy people."/>

        <meta property="og:url" content="https://kbkwelfareassociation.com/cloth-distribution.html"/>
        </Helmet>
      <section className="page-header" style={{ backgroundImage: "url(/images/new/cloth-distribution.jpg)" }}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Cloths Distribution </h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Cloths Distribution  </li>
                    </ul>
                </div>
            </div>
      </section>
      <section className="make-donation-two">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="make-donation-two__left">
                        <div className="block-title text-left">
                            <h1>Cloths Distribution</h1>
                        </div>
                        <p className="make-donation-two__text">
                            Free distribution of raincoats and blankets and other clothing is also one of the essential works taken up by
                            <a href="https://kbkwelfareassociation.com/"> KBK welfare association.</a>
                            <br />

                            Especially rain coats and blankets becoming more expensive and daily wager earners cannot afford to purchase these.
                            <br />
                            Men, women, children and seniors live without blankets and do not know about raincoats.
                            <br />

                            KBK welfare association has actively worked in this particular work and widely distributed raincoats, and blankets to many families.

                            <br />
                            We are working devotedly on this activity and plan to help many poor people in the near future for the <a href="https://en.wikipedia.org/wiki/Poverty">poor people </a>living in the State of Telangana.
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="make-donation-two__right">
                        <div className="make-donation-two__single-img">
                            <img src="/images/clothes.jpg" alt="Cloth Donation" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}
