import './App.css';
import Navbar from './components/Navbar';
import{BrowserRouter, Routes, Route} from 'react-router-dom';
import Slider from './components/index/slider';
import AboutSection from './components/index/AboutSection';
import OurWorks from './components/index/OurWorks';
import 'bootstrap/dist/css/bootstrap.min.css';
import OurNews from './components/index/OurNews';
import WhatTheySay from './components/index/WhatTheySay';
import OurSponsors from './components/index/OurSponsers';
import JoinKBK from './components/index/JoinKBK';
import Footer from './components/Footer';
import AboutBanner from './components/about/AboutBanner';
import AboutContent from './components/about/AboutContent';
import FoodDistribution from './components/ourwork/FoodDistribution';
import GroceryDistribution from './components/ourwork/GroceryDistribution';
import SchoolStationary from './components/ourwork/SchoolStationary';
import ClothsDistribution from './components/ourwork/ClothsDistribution';
import Covid19Essentials from './components/ourwork/Covid19Essentials';
import EventsGallery from './components/eventsgallery/EventsGallery';
import News from './components/news/News';
import NewsSlider from './components/news/NewsSlider';
import PartnerWithUs from './components/partner/PartnerWithUs';
import Contact from './components/contact/Contact';
import GottimuklaZPHS from './components/eventsgallery/GottimuklaZPHS';
import SafeCharitableFoundation from './components/eventsgallery/SafeCharitableFoundation';
import AmmaodiOrphanage from './components/eventsgallery/AmmaodiOrphanage';
import AmmaNanna from './components/eventsgallery/AmmaNanna';
import AmmaNannaOrphanage from './components/eventsgallery/AmmaNannaOrphanage';
import AnjanideviCharitableTrust from './components/eventsgallery/AnjanideviCharitableTrust';
import Pyramvijayabarathi from './components/eventsgallery/Pyramvijayabarathi';
import TopContainer from './components/index/TopContainer';
import ScrollToTop from './components/ScrollToTop';
import MedicalCamp from './components/eventsgallery/MedicalCamp';
import LalanaFoundation from './components/eventsgallery/LalanaFoundation';
import FinancialAssistance from './components/eventsgallery/FinancialAssistance';
import RainFloods from './components/eventsgallery/RainFloods';
import Covid from './components/eventsgallery/covid';
import SisterMarriage from './components/eventsgallery/SisterMarriage';
import FoodDistributionNacharam from './components/eventsgallery/FoodDistributionNacharam';
import FoodDonationAshakuteer from './components/eventsgallery/FoodDonationAshakuteer';





function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
      <Navbar/>
      <Routes>
      <Route path='/' element={
          <>
            <Slider/>
            <TopContainer/>
            <AboutSection/>
            <OurWorks/>
            <OurNews/>
            <WhatTheySay/>
            <OurSponsors/>
            <JoinKBK/>
            
          </>
        } />
        <Route path='/about' element={
          <>
           <AboutBanner/>
           <AboutContent/>
          </>
        } />
        <Route path='/food-distribution' element={
          <>
          <FoodDistribution/>
          </>
        } />
        <Route path='/grocery' element={
          <>
          <GroceryDistribution/>
          </>
        } />
        <Route path='/school-stationary' element={
          <>
          <SchoolStationary/>
          </>
        } />
         <Route path='/cloth-distribution' element={
          <>
          <ClothsDistribution/>
          </>
        } />
         <Route path='/covid-19' element={
          <>
          <Covid19Essentials/>
          </>
        } />
        <Route path='/gallery' element={
          <>
          <EventsGallery/>
          </>
        } />
        <Route path='/news' element={
          <>
          <News/>
          <NewsSlider/>
          </>
        } />
        <Route path='/partner-with-us' element={
          <>
          <PartnerWithUs/>
          </>
        } />
        <Route path='/contact' element={
          <>
          <Contact/>
          </>
        } />
        <Route path='/gottimukla-zila-parishad-government-school' element={
          <>
          <GottimuklaZPHS/>
          </>
        } />
        <Route path='/safe-charitable-foundation' element={
          <>
          <SafeCharitableFoundation/>
          </>
        } />
        <Route path='/ammaodi-orphanage' element={
          <>
          <AmmaodiOrphanage/>
          </>
        } />
        <Route path='/amma-nanna-andha-ashramam' element={
          <>
          <AmmaNanna/>
          </>
        } />
        <Route path='/amma-nanna-ashramam' element={
          <>
          <AmmaNannaOrphanage/>
          </>
        } />
        <Route path='/medical-camp' element={
          <>
          <MedicalCamp/>
          </>
        } />
        <Route path='/financial-assistance' element={
          <>
          <FinancialAssistance/>
          </>
        } />
        <Route path='/lalana-foundation' element={
          <>
          <LalanaFoundation/>
          </>
        } />
        <Route path='/anjanidevi-charitable-trust' element={
          <>
          <AnjanideviCharitableTrust/>
          </>
        } />
        <Route path='/pyramvijayabarathi' element={
          <>
          <Pyramvijayabarathi/>
          </>
        } />
        <Route path='/rainfloods' element={
          <>
          <RainFloods/>
          </>
        } />
        <Route path='/covid' element={
          <>
          <Covid/>
          </>
        } />
        <Route path='/sistermarriage' element={
          <>
          <SisterMarriage/>
          </>
        } />

        <Route path='/food-distribution-event-at-sadhana-institute-in-nacharam' element={
          <>
          <FoodDistributionNacharam></FoodDistributionNacharam>
          </>
        } />

        <Route path='/food-distribution-event-at-ashakuteer-orphanage' element={
          <>
          <FoodDonationAshakuteer></FoodDonationAshakuteer>
          </>
        } />


        
      </Routes>
      
      <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
