import React, { useEffect } from 'react';
import { Container, Card, Carousel, Button } from 'react-bootstrap';
import $ from 'jquery'; // Import jQuery (ensure it's installed)
import { Link } from 'react-router-dom';


const OurWorks = () => {
  useEffect(() => {
    // Handling the sliding of one image at a time
    $('.carousel').each(function () {
      const minPerSlide = 1;
      const next = $(this).next();
      if (!next.length) {
        $(this).siblings(':first-child');
      }
      next.children(':first-child').clone().appendTo($(this));

      for (let i = 0; i < minPerSlide; i++) {
        next.next().children(':first-child').clone().appendTo($(this));
      }
    });
  }, []);

  const cardItems = [
    {
        text: 'Food Distribution',
        image: 'images/food.webp',
        paragraph: "Among the locals, we are distributing food. They look very happy and satisfied.",
        buttonText: "Read More"
      },
    { text: 'Groceries ', 
      image: 'images/gro.webp',
      paragraph: "Due to loss of work, daily labor cannot purchase groceries. So, we help them with the supply ",
      buttonText: "Read More"
     },
    { text: 'School stationery', 
    image: 'images/bok.webp',
    paragraph: "Due to loss of work, daily labor cannot purchase groceries. So, we help them with the supply ",
    buttonText: "Read More"
    },
    { text: 'Cloth distribution', 
    image: 'images/clothes.webp',
    paragraph: "Raincoats and blankets are very expensive and poor people cannot afford to buy.",
    buttonText: "Read More"
   },
    { text: 'Covid-19 Essentials',
     image: 'images/covid.webp',
     paragraph: "Now we are in a pandemic period and this is a very difficult phase for poor people. KBK welfare .",
     buttonText: "Read More"
    },
    {
        text: 'Food Distribution',
        image: 'images/food.webp',
        paragraph: "Among the locals, we are distributing food. They look very happy and satisfied.",
        buttonText: "Read More"
      },
    { text: 'Groceries ', 
      image: 'images/gro.webp',
      paragraph: "Due to loss of work, daily labor cannot purchase groceries. So, we help them with the supply ",
      buttonText: "Read More"
     },
    { text: 'School stationery', 
    image: 'images/bok.webp',
    paragraph: "Due to loss of work, daily labor cannot purchase groceries. So, we help them with the supply ",
    buttonText: "Read More"
    },
  ];

  const chunkArray = (arr, chunkSize) => {
    const chunkedArray = [];
    const screenSize = window.innerWidth; // Get the screen width dynamically

    let itemsPerSlide = chunkSize;
    if (screenSize < 768) {
      itemsPerSlide = 1; // Display 1 card per slide on mobile
    }

    for (let i = 0; i < arr.length; i += itemsPerSlide) {
      chunkedArray.push(arr.slice(i, i + itemsPerSlide));
    }
    return chunkedArray;
  };

  const chunkedItems = chunkArray(cardItems, 4); 

  return (
    <div>
      <Container className='py-5'>
        <div className='block-title text-center'>
            <h4 className='text-center'>Help the People</h4>
            <h2 className='text-center'>Our Works</h2>
        </div>
        
        <Carousel interval={4000} pause={false} indicators={false} className="carousel slide">
          {chunkedItems.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="row">
                {chunk.map((item, itemIndex) => (
                  <div className="col-md-3 col-12 mb-4" key={itemIndex}>
                    <Card className="h-100">
                      <Card.Img variant="top" src={item.image} alt={`Slide ${index * 4 + itemIndex + 1}`} />
                      <Card.Body>
                        <Card.Title>{item.text}</Card.Title>
                        <Card.Text>{item.paragraph}</Card.Text> 
                        <div className="main-nav__right__btn-one">
                            <Link to="about">Read More</Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default OurWorks;
