import React from "react";

export default function OurNews() {
    return (
        <div>
            <section className="we-need-help">
                <div className="we-nned-help-bg">
                    <img src="images/news1.png" alt="" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="we-need-help__left">
                                <div className="block-title text-center">
                                    <h4>Our News</h4>
                                </div>
                                <div className="we-need-help__faqs">
                                    <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion">
                                        <p>We are working hard to reach out to many people who are in need of our services.</p>

                                        <p>We work with a process to carry out our charity works.</p>
                                        <p>We are very excited to share big events and our work among locals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="we-need-help__right">
                                <div className="we-need-help__img">
                                    <img src="images/news1.webp" alt="KBK welfare News" />
                                    <div className="we-need-help__give">
                                        <div className="icon">
                                            <span className="icon-charity-1"></span>
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Let’s Give us your <br /> Helping Hand
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
