import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';


export default function FinancialAssistance() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Financial Assistance</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Financial Assistance </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">KBK Welfare Association Provided Financial Assistance </h2>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className="col-md-6 item">
                            <a href="images/new/Mallikarjun2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/Mallikarjun2.jpg" alt="Img" /></a>
                        </div>
                        <div className='col-md-6'>
                        <p className='text-dark'>Mallikarjun, an electrician from Andhra Pradesh, is facing a challenging situation as his mother battles a serious illness. Recognizing the need for financial support to ensure adequate medical treatment, the KBK Welfare Association has generously provided financial assistance totaling 20,000 rupees to cover medical expenses</p>
                        </div>
                    </div>
                    
                    <div className='row align-items-center mob-cr'>
                    <div className='col-md-6'>
                        <p className='text-dark'>In recent events, the daughter of a plumber residing in Hyderabad was married. Recognizing the significant financial challenges faced by Yadaiah's family, the KBK Welfare Association provided financial assistance of 10,000 rupees to support the wedding expenses.</p>
                        </div>
                    <div className="col-md-6 item">
                        <a href="images/new/Yadaiah1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/Yadaiah1.jpg" alt="Img" /></a>
                    </div>
                    </div>
                    
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
