import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';


export default function SafeCharitableFoundation() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Safe Charitable Foundation</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Safe Charitable Foundation</li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Safe Charitable Foundation</h2>
                            <p className='text-dark'>The KBK Welfare Association organised food distribution at the Safe Charitable Trust.</p>
                            <p className='text-dark'>The KBK Welfare Association, a branch of the KBK Group, held a food distribution programme for orphans at the Safe Charitable Trust in Vanasthalipuram. This ceremony commemorated the birthday of Bhavysree Kshitija, daughter of KBK Group CEO K Bharat Kumar and Jaya Vaishnavi</p>

                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/safe-charity1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/safe-charity1.jpg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/safe-charity2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/safe-charity2.jpg" alt="orphans welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/safe-charity3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/safe-charity3.jpg" alt="Food Distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/safe-charity4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/safe-charity4.jpg" alt="Food Donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/safe-charity5.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/safe-charity5.jpg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/safe-charity6.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/safe-charity6.jpg" alt="food distribution" /></a>
                    </div>
                    
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
