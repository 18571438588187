import React from 'react'

export default function TopContainer() {
  return (
    <div>
      <div className="feature-one__inner">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">
                            <div className="feature-one__single feature-one__single-first-item">
                                <div className="feature-one__icon-wrap">
                                    <div className="feature-one__icon-box">
                                        <span className="icon-heart"></span>
                                        <div className="feature-one__icon-box-img">
                                            <img src="/images/three_iocn_box_bg.png" alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="feature-one__icon-text-box">
                                        <h4>Be a  <br/> volunteer</h4>
                                    </div>
                                </div>
                                <p className="feature-one__icons-single-text">
                                    We are in need of volunteers as our work is growing rapidly.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="feature-one__single feature-one__single-second-item">
                                <div className="feature-one__icon-wrap">
                                    <div className="feature-one__icon-box feature-one__icon-box-two">
                                        <span className="icon-wallet-filled-money-tool"></span>
                                        <div className="feature-one__icon-box-img">
                                            <img src="/images/three_iocn_box_bg-2.png" alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="feature-one__icon-text-box">
                                        <h4>Share <br/> with us</h4>
                                    </div>
                                </div>
                                <p className="feature-one__icons-single-text">
                                    Did you notice a problem or any issue in a community? Tell us so that we can do our best.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="feature-one__single feature-one__single-third-item">
                                <div className="feature-one__icon-wrap">
                                    <div className="feature-one__icon-box feature-one__icon-box-three">
                                        <span className="icon-charity"></span>
                                        <div className="feature-one__icon-box-img">
                                            <img src="/images/three_iocn_box_bg-3.png" alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="feature-one__icon-text-box">
                                        <h4> Small <br/> Donation </h4>
                                    </div>
                                </div>
                                <p className="feature-one__icons-single-text">
                                    A small donation that you give can help in a big way.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
  )
}
