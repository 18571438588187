import React from 'react'
import {Helmet} from "react-helmet"
import { Link } from 'react-router-dom';

export default function FoodDonationAshakuteer() {
  return (
    <div>
        <Helmet>
        <title> Food Distribution Event at Ashakuteer Orphanage</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Food Distribution Events</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Food Distribution </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Food Distribution Event at Ashakuteer Orphanage</h2>
                            <p className='text-dark'>KBK Welfare Association hosted a food distribution event on July 23, 2024, to celebrate the birthday of baby Bhavysri Kshitija, daughter of Dr. Kakkireni Bharath Kumar, Chairman and CEO of KBK Group, and Jaya Vyshnavi. This program served meals to visually challenged orphans at the Ashakuteer Orphanage in Begumpet, Hyderabad.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/1.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/2.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/3.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/4.jpg" alt="Img"/></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/5.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/5.jpg" alt="Img"/></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/6.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/6.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/7.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/7.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/8.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/8.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/9.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/9.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/10.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/10.jpg" alt="Img" /></a>
                    </div>
                    
                    
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
