import React from 'react';
import {Helmet} from "react-helmet"
import { Link } from 'react-router-dom';

export default function SisterMarriage() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Sister Marriage</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Sister Marriage</li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Sister Marriage</h2>
                            <p className='text-dark'>The KBK Welfare Association provided financial assistance for the marriage of an underprivileged woman</p>
                            <p className='text-dark'>As part of its Corporate Social Responsibility program, the KBK Group has implemented service initiatives through the KBK Welfare Association. In a specific case, the association provided financial assistance for the wedding of a young woman. Anil, a laborer in Hyderabad, received Rs. 10,000 in financial aid from the KBK Welfare Association to support the marriage expenses of his sister.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/38.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/38.jpg" alt="Money Donation for needy" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/39.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/39.jpg" alt="Charity for needy" /></a>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
