import React from 'react';
import {Helmet} from "react-helmet"
import { Link } from 'react-router-dom';

export default function AnjanideviCharitableTrust() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
      <section className="page-header" style={{backgroundImage: "url(images/gallerybanner.jpg)"}}>
        <div className="container">
            <div className="page-header__inner">
                <h2>ANJANIDEVI CHARITABLE TRUST</h2>
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                    <li><span>/</span></li>
                    <li>ANJANIDEVI CHARITABLE TRUST</li>
                </ul>
            </div>
        </div>
    </section>
    <div className="lightbox-gallery">
        <div className="container">
            <div className="row photos">
                <div className="col-lg-12">
                    <div className="block-title text-left">
                        <br />
                        <br />

                        <h2 className="ab-k">ANJANIDEVI CHARITABLE TRUST</h2>
                        <p className='text-dark'>KBK Welfare Association organises a food donation programme at Anjanidevi Charitable Trust in Pasumamula.</p>
                        <p className='text-dark'>The KBK Welfare Association, a subsidiary of the KBK Group, organised a food distribution programme at the Anjanidevi Charitable Trust in Pasumamula. This ceremony was held to mark the birthday of KBK Group CEO K Bharat Kumar, and free meals were distributed to orphans.</p>
                    </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/kr-1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/kr-1.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/kr-2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/kr-2.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/kr-3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/kr-3.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/kr-4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/kr-4.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/kr-5.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/kr-5.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/kr-6.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/kr-6.jpg" /></a>
                </div>
            </div>
            <br />
            <br />
        </div>
    </div>


    </div>
  )
}
