import React from 'react';
import {Helmet} from "react-helmet"
import { Link } from 'react-router-dom';

export default function RainFloods() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Rain Floods </h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Rain Floods  </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Rain Floods</h2>
                            <p className='text-dark'>KBK Welfare Association provided financial assistance to flood victims </p>
                            <p className='text-dark'>During the heavy rains of 2020, the city of Hyderabad experienced severe flooding, resulting in the submersion of numerous residential houses and tragic loss of lives. Among those affected was Srikanth, a resident whose house was severely damaged. Upon learning of his plight, Bharat Kumar, the head of KBK Group, extended financial assistance of ten thousand rupees through the KBK Welfare Association.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/01.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/01.jpg" alt="donation for orphanage" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/05.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/05.jpg" alt="welfare program" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/09.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/09.jpg" alt="Charity for needy" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/01.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/01.jpg" alt="donation for orphanage" /></a>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
