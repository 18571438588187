import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';


export default function AmmaNannaOrphanage() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> AMMA NANNA ANADHA ASHRAMAM </h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> AMMA NANNA ANADHA ASHRAMAM  </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">AMMA NANNA ANADHA ASHRAMAM</h2>
                            <p className='text-dark'>The KBK Welfare Association organized a food donation program at the Amma Nanna Orphanage Shrine in Chautuppal town of Yadadri Bhuvanagiri district. The event was arranged in honor of the wedding anniversary of the Sahit Sarika couple, who reside in America. The Annadanam provided meals for 600 people at the ashram. KBK Group founder Kakkireni Bharat Kumar and his wife Jaya Vaishnavi, who frequently organize such service programs through the KBK Welfare Association, were specially acknowledged for their contributions.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-01.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8368.jpg" alt="Food Distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-02.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8427.jpeg" alt="Feed the needy programme" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-03.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8428.jpeg" alt="birthday celebration" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-04.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8440.jpeg" alt="food donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-05.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8503.jpeg" alt="old age welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-06.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8508.jpeg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-07.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8537.jpeg" alt="oldage home" /></a>
                    </div>
                    
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
