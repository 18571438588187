import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function OurSponsors() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  
  // Array of sponsor image URLs
  const sponsorImages = [
    'images/sp-1.webp',
    'images/sp-2.webp',
    'images/sp-3.webp',
    'images/sp-4.webp',
    'images/sp-5.webp',
    'images/sp-6.webp',
    // Add more sponsor image URLs as needed
  ];

  return (
    <div>
      <div className="block-title text-center">
        <br/>
        <h2>Our Sponsors</h2>
        <br/>
    </div>
      <div className="slider-container" style={{ maxWidth: '100%' }}>
        <Slider {...settings}>
          {sponsorImages.map((image, index) => (
            <div key={index} className="slide-item">
              <img src={image} alt={`Sponsor ${index + 1}`} style={{ maxWidth: '100%' }} />
            </div>
          ))}
        </Slider>
      </div>

    </div>
  );
}
