import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

export default function GottimuklaZPHS() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2>Gottimukla Zila Parishad Government School</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li>Gottimukla Zila Parishad Government School</li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Gottimukla Zila Parishad Government School</h2>
                            <p className='text-dark'>The KBK Welfare Association donated notebooks to government school students </p>
                            <p className='text-dark'>The KBK Welfare Association distributed notebooks to students of government schools. This initiative, undertaken to coincide with the commencement of the academic year, aimed to support economically disadvantaged students. Representatives of the association visited the Gottimukkala Zilla Parishad Government School in Vikarabad district and distributed free notebooks to approximately 150 students.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/welfare-edu1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/welfare-edu1.jpg" alt="food distribution" /></a>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/welfare-edu2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/welfare-edu2.jpg" alt="orphans welfare" /></a>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/welfare-edu3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/welfare-edu3.jpg" alt="Food Distribution" /></a>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/welfare-edu4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/welfare-edu4.jpg" alt="Food Donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/welfare-edu5.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/welfare-edu5.jpg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/welfare-edu6.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/welfare-edu6.jpg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/welfare-edu7.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/welfare-edu7.jpg" alt="orphans donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/welfare-edu8.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/welfare-edu8.jpg" alt="orphans welfare program" /></a>
                    </div>
                    
                </div>
                <br />
                <br />
            </div>
        </div>

        
    </div>
  )
}
