import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';


export default function MedicalCamp() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Medical Camp</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Medical Camp </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">KBK Multispecialty Hospital Organized A Mega Free Medical Camp</h2>
                            <p className='text-dark'>KBK Welfare Association Hosts A Free Mega Health Camp In Khammam
                               The KBK Welfare Association conducted a free Mega Health Camp in collaboration with the KBK Multi-Specialty Hospital as part of its monthly activities. The camp was held at Pandurangapuram in Khammam town, where doctors from the KBK Hospital provided free medical examinations and distributed medicine to approximately 200 people.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8100.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8100.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8101.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8101.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8209.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8209.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8347.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8347.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8187.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8187.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8124.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8124.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8146.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8146.jpg" alt="Img"/></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8180.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8180.jpg" alt="Img"/></a>
                    </div>
                    
                    
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8210.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8210.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8220.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8220.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8241.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8241.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8255.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8255.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/_KBK8261.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8261.jpg" alt="Img" /></a>
                    </div>
                    
                    
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
