import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function GroceryDistribution() {
  return (
    <div>
        <Helmet>
        <title>Grocery Donation in Hyderabad | Grocery Distribution to Needy</title>

        <meta name="Description" content="We prepare ourselves to contribute our best to the needy people, Orphanages and old age people and help with grocery donation in hyderabad."/>

        <meta name="Keywords" content="Grocery Donation, Charity Food Donation, KBK Welfare Work, Giving Food for the Needy, Welfare Associations in Hyderabad, NGO Organizations, Non Government Organizations"/>

        <meta http-equiv="cleartype" content="on"/>

        <meta name="author" content="Kbkwelfare"/>

        <meta name=" https://kbkwelfareassociation.com/grocery.html" CONTENT="Grocery donation, Welfare Associations in Hyderabad"/>

        <meta name="rating" CONTENT="General"/>

        <meta name="robots" content="index,follow,noarchive"/>

        <meta name="revisit-after" CONTENT="daily"/>

        <meta content="English" name="language"/>

        <meta property="og:type" content="website"/>

        <meta property="og:locale" content="en_US"/>

        <meta property="og:title" content="Grocery donation in Hyderabad | Grocery Distribution to needy"/>

        <meta property="og:description" content="We prepare ourselves to contribute our best to the needy people, orphanages and old age people and help with grocery donation in hyderabad."/>

        <meta property="og:url" content="https://kbkwelfareassociation.com/grocery.html"/>

        <meta property="og:site_name" content="Grocery donation in Hyderabad | Grocery Distribution to needy"/>
        </Helmet>
      <section className="page-header" style={{ backgroundImage: "url(/images/new/grocery-distribution.jpg)" }}>
        <div className="container">
            <div className="page-header__inner">
            <h2>  Grocery Distribution  </h2>
            <ul className="thm-breadcrumb list-unstyled">
                <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                <li><span>/</span></li>
                <li>  Grocery Distribution   </li>
            </ul>
            </div>
        </div>
      </section>
      <section className="make-donation-two">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="make-donation-two__left">
                        <div className="block-title text-left">
                            <h1>Grocery Distribution</h1>
                        </div>
                        <p className="make-donation-two__text">
                            Groceries like rice, lentils, vegetables and oil are daily cooking essentials. In many of the backward regions in the State of Telangana, we noticed that people cannot afford to purchase groceries as they do not have
                            access to shops and vendors.
                            <br />
                            Especially during the lockdown period and cyclone-affected time, we noticed that they look for a lot of help and they do not receive it in time.

                            <br />
                            We notice that in the months of July and August, at the time of monsoon season, the Telangana region receives a lot of rain and they cause a lot of flooding causing a lot of home damage to the poor people.
                        </p>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6">
                    <div className="make-donation-two__right">
                        <div className="make-donation-two__single-img">
                            <img src="/images/gro.jpg" alt="Grocery Donation" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <p className="make-donation-two__text">
                        We prepare ourselves to contribute our best to the poor people and help with grocery distribution.
                        <br />
                        Having seen their problems, the <a href="https://kbkwelfareassociation.com/">KBK welfare association </a> has added grocery distribution as one of our main works.

                        <br />
                        We accumulate vast quantities of rice, wheat flour, lentils, vegetables and oil and with the help of our volunteers, we <a href="https://kbkwelfareassociation.com/gallery.html">distribute groceries.</a>
                        <br />

                        This activity is done regularly as poor communities continue to have difficulties in finding daily wage jobs.
                        <br />
                        In the year 2020, we visited many regions and performed grocery distribution actively and the locals were very happy.
                    </p>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}
