import React from 'react';
import { Link } from 'react-router-dom'

 import {Helmet} from "react-helmet";
  export default function Slider() { return (
    <div>
        <Helmet>
            <title>KBK Welfare Association in Hyderabad | NGO in Hyderabad .</title>
            <meta name="Description" content="KBK welfare asssociation is one of the top ngo in hyderabad is mission to help in health care, living and essentials for needy around hyderabad." />
    
            <meta name="Keywords" content="Welfare Associations in Hyderabad, KBK Welfare Association in Hyderabad, Famous NGOs in Hyderabad, Food Donation, Grocery Donation, Clothes Donation, NGO organizations" />
    
            <meta http-equiv="cleartype" content="on" />
    
            <meta name="author" content="Kbkwelfare" />
    
            <meta name=" https://kbkwelfareassociation.com/index.html" content=" Food Donation , Grocery Donation" />
    
            <meta name="rating" content="General" />
    
            <meta name="robots" content="index,follow,noarchive" />
    
            <meta name="revisit-after" content="daily" />
    
            <meta content="English" name="language" />
    
            <link href="https://kbkwelfareassociation.com/opensearch.xml" rel="search" title="KBK Welfare Association | NGO Organizations" type="application/opensearchdescription+xml" />
    
            <link rel="canonical" href=" https://kbkwelfareassociation.com/index.html" />
    
            <meta property="og:type" content="website" />
    
            <meta property="og:locale" content="en_US" />
    
            <meta property="og:title" content="KBK Welfare Association in hyderabad | NGO in Hyderabad " />
    
            <meta property="og:description" content="KBK welfare asssociation is one of the top ngo in hyderabad is mission to help in health care, living and essentials for needy around hyd." />
    
            <meta property="og:url" content="https://kbkwelfareassociation.com/index.html" />
    
            <meta property="og:site_name" content="KBK Welfare Association in hyderabad | NGO in Hyderabad " />
        </Helmet>
        <div id="carouselExampleCaptions" className="carousel slide">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active swiper-slide__inner">
                    <img src="images/banner2.jpg" className="d-block w-100" alt="img" />
                    <div className="carousel-caption">
                        <p>Reaching to help those who lack in primary resources.</p>
                        <h2 className="mk-eml" id="captions">Funding education, young women marriages and clothing</h2>
                        <Link to="about" className="thm-btn fn m-none">Learn More</Link>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="images/banner.jpg" className="d-block w-100" alt="img" />
                    <div className="carousel-caption">
                        <p>Reaching to help those who lack in primary resources.</p>
                        <h2 className="mk-eml" id="captions">Making a small difference in the lives of people</h2>
                        <Link to="about" className="thm-btn fn m-none">Learn More</Link>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="images/banner1.jpg" className="d-block w-100" alt="img" />
                    <div className="carousel-caption">
                        <p>Reaching to help those who lack in primary resources.</p>
                        <h2 className="mk-eml" id="captions">Taking care of people when natural calamities strike</h2>
                        <Link to="about" className="thm-btn fn m-none">Learn More</Link>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    
    ) }
    