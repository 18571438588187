import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';


export default function News() {
  return (
    <div>
      <Helmet>
      <title>NGO organizations in hyderabad | KBK Welfare Association</title>

          <meta name="Description" content="KBK welfare asssociation is one of the top welfare associations in hyderabad is mission to foster health care, living and essentials for needy around hyderabad."/>

          <meta name="Keywords" content="KBK Welfare Association, NGO Organizations, Food Donation, Grocery Donation, KBK Welfare Work, Famous NGOs in Hyderabad"/>

          <meta http-equiv="cleartype" content="on"/>

          <meta name="author" content="Kbkwelfare"/>

          <meta name=" https://kbkwelfareassociation.com/gallery.html" CONTENT="KBK Welfare Association, Welfare Associations in Hyderabad"/>

          <meta name="rating" CONTENT="General"/>

          <meta name="robots" content="index,follow,noarchive"/>

          <meta name="revisit-after" CONTENT="daily"/>

          <meta content="English" name="language"/>

          <meta property="og:type" content="website"/>

          <meta property="og:locale" content="en_US"/>

          <meta property="og:title " content="NGO Organizations in Hyderabad | KBK Welfare Association"/>

          <meta property="og:description" content="KBK welfare asssociation is one of the top welfare associations in hyderabad is mission to foster health care, living and essentials for needy around hyderabad."/>

          <meta property="og:url" content="https://kbkwelfareassociation.com/gallery.html"/>

          <meta property="og:site_name" content="NGO Organizations in Hyderabad | KBK Welfare Association"/>
      </Helmet>
      <section className="page-header" style={{ backgroundImage: "url(/images/news.jpg)" }}>
            <div className="container">
                <div className="page-header__inner">
                    <h2>News</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li>News</li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
  )
}
