import React from 'react';
import {Helmet} from "react-helmet"
import { Link } from 'react-router-dom';

export default function AmmaodiOrphanage() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> AMMAODI ORPHANAGE </h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> AMMAODI ORPHANAGE  </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">AMMAODI ORPHANAGE</h2>
                            <p className='text-dark'>KBK Welfare Association Organizes Food Donation Program at Amma Odi Orphanage </p>
                            <p className='text-dark'>The KBK Welfare Association, a KBK Group subsidiary, organized a food distribution programme at the Amma Odi Orphanage in Uppal. This event was undertaken to commemorate the birthday of KBK Group CEO K Bharat Kumar, during which free meals were distributed to orphans.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-1.jpg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-2.jpg" alt="orphans welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-3.jpg" alt="Food Distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-4.jpg" alt="Food Donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-5.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-5.jpg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-6.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-6.jpg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-7.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-7.jpg" alt="orphans donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-8.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-8.jpg" alt="orphans welfare program" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-9.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-9.jpg" alt="ngo for food donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-10.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-10.jpg" alt="stationary donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/me-11.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/me-11.jpg" alt="child welfare" /></a>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
