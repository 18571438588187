import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

export default function Pyramvijayabarathi() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2>  PYRAM VIJAYA BARATHI VIDYA SAGAR CHARITABLE TRUST   </h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li>  PYRAM VIJAYA BARATHI VIDYA SAGAR CHARITABLE TRUST   </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">PYRAM VIJAYA BARATHI VIDYA SAGAR CHARITABLE TRUST</h2>
                            <p className='text-dark'>KBK Welfare Association organizes a food donation program at PYRAM VIJAYA BARATHI VIDYA SAGAR CHARITABLE TRUST </p>
                            <p className='text-dark'>The KBK Welfare Association, a subsidiary of the KBK Group, held a food donation drive at the PYRAM VIJAYA BARATHI VIDYA SAGAR CHARITABLE TRUST in Nadargul. This event commemorated the birthday of KBK Group CEO K Bharat Kumar and Jaya Vyshnavi's daughter, Baby Bhavysri Kshitija, by delivering free meals to orphans.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dd-1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dd-1.jpg" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dd-2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dd-2.jpg" alt="Food Distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dd-3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dd-3.jpg" alt="Orphan birthday celebration" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dd-4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dd-4.jpg" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dd-5.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dd-5.jpg" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dd-6.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dd-6.jpg" /></a>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dd-7.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dd-7.jpg" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dd-8.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dd-8.jpg" /></a>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
