import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',    
    phone: '',
    email: '',   
    message: '',
    recaptchaValue: null, // Initialize the reCAPTCHA value
  });

  const [showRecaptcha, setShowRecaptcha] = useState(false);

  useEffect(() => {
    // Load the reCAPTCHA script when the component mounts
    if (showRecaptcha) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      document.head.appendChild(script);
    }
  }, [showRecaptcha]);



  const [error, setError] = useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Check if the reCAPTCHA value is provided
    if (!formData.recaptchaValue) {
      setError('Please complete the reCAPTCHA verification.');
      return;
    }

    // Reset the error state
    setError(null);

    // Perform your form submission logic here
    emailjs.sendForm('service_ap5okg8', 'template_zu31yci', form.current, 'FcHxBdVjVdXngu5xC')
      .then((result) => {            
          alert("Message Sent Successfully");

          setFormData({
            name: '',
            phone: '',
            email: '',
            message: '',
            recaptchaValue: '',
          });

      }, (error) => {
          alert("Error! Please try again.");
      });
    //console.log('Form submitted with reCAPTCHA:', formData);
  };

  const handleRecaptchaChange = (value) => {
    // Update the form state with the reCAPTCHA value
    setFormData({
      ...formData,
      recaptchaValue: value,
    });

    // Clear the error when the user completes the reCAPTCHA
    setError(null);
  };

  const handleInputChange = (e) => {
    // Update the form data as the user types
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (!showRecaptcha) {
      setShowRecaptcha(true);
    }

  };
    
    return (
        <div>
          <Helmet>
          <title>Kbk welfare association in hyderabad | Contact Us</title>

          <meta name="Description" content="KBK welfare association as an ngo help those who lack in primary resources. Making a small difference in the lives of people."/>

          <meta name="Keywords" content="KBK Welfare Association, NGO Organizations, Food Donation, Grocery Donation, Clothes Donation, KBK Welfare Work, Famous NGOs in hyderabad, NGO Organizations in Hyderabad"/>

          <meta http-equiv="cleartype" content="on"/>

          <meta name="author" content="Kbkwelfare"/>

          <meta name=" https://kbkwelfareassociation.com/contact.html" CONTENT="NGO Organizations, Famous NGOs in Hyderabad"/>

          <meta name="rating" CONTENT="General"/>

          <meta name="robots" content="index,follow,noarchive"/>

          <meta name="revisit-after" CONTENT="daily"/>

          <meta content="English" name="language"/>

          <meta property="og:type" content="website"/>

          <meta property="og:locale" content="en_US"/>

          <meta property="og:title " content="KBK Welfare Association | Contact Us"/>

          <meta property="og:description" content="KBK welfare association as an ngo help those who lack in primary resources. Making a small difference in the lives of people."/>

          <meta property="og:url" content="https://kbkwelfareassociation.com/contact.html"/>

          <meta property="og:site_name" content="KBK Welfare Association | Contact Us"/>
          </Helmet>
            <section className="page-header" style={{backgroundImage: "url(/images/contact.jpg)" }}>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Contact</h2>
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                            <li><span>/</span></li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
            </section>
            
           <section className='contact-page'>
           <div className="contactus-section">
                <div className="container">
                    <div className="block-title text-center">
                        <h4>Any Queries</h4>
                        <h4 className='text-center'>Contact With Us</h4>
                    </div>
                    <div className="row">
                       
                        <div className="col-md-8 contact-form">
                        <h3 className='pb-3'>Contact KBK Welfare Association</h3>
                          <form className='row' autoComplete='off' ref={form} onSubmit={handleFormSubmit}>
                            {/* Your other form fields */}

                              <div className="col-md-12 form-group mb-4">                                    
                                  <input value={formData.name} onChange={handleInputChange} type="text" name='name' placeholder='Name *' onKeyUp={(e) => {e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');}} className="form-control" required />
                              </div>
                              <div className="col-md-12 form-group mb-4">                                    
                                  <input value={formData.email} onChange={handleInputChange} type="email" name='email' placeholder='Email Address*' className="form-control" required />
                              </div>

                              

                              <div className="col-md-12 form-group mb-4">                                    
                                  <input value={formData.phone} onChange={handleInputChange} type="text" maxLength={13} minLength={10} name='phone' placeholder='Phone No *' className="form-control" onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '');}} required />
                              </div>

                              
                             

                              <div className="col-md-12 form-group mb-4">                                    
                                  <textarea value={formData.message} onChange={handleInputChange} className='form-control' rows={5} name='message' placeholder='Message*'></textarea>
                              </div>
                            
                            {/* Add the reCAPTCHA component */}

                            {showRecaptcha && (
                              <>
                                <ReCAPTCHA
                                  sitekey="6Le73kIpAAAAAC6BVRWehzj4LUyCrQABHZeH-MUd"
                                  onChange={handleRecaptchaChange}
                                  theme="light"
                                />
                                
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                              </>
                            )}
                            
                            <div className="col-md-12 form-group mt-3">
                                <button type='submit' value="Send" className="btn btn-primary px-5 mx-auto text-center">Submit</button>
                            </div>            
                          </form>
                        
                        </div>
                        <div className="col-xl-4 d-flex align-items-stretch">
                            <div className="contact-page__info-box">
                                <div className="contact-page__info-box-address">
                                    <h4 className="contact-page__info-box-tilte">Address</h4>
                                    <p className="contact-page__info-box-address-text">
                                        <i className="fas fa-map-marker-alt px-1"></i>

                                        H-No:2-1-8/4/1/NR, Suite 2D, <br />
                                        Saraswathi Colony, Uppal, Hyd-39, TS, India
                                    </p>
                                </div><br/>
                                <div className="contact-page__info-box-phone">
                                    <i className="fas fa-phone-square-alt"></i>
                                    <a className="num-rell-l" href="tel:99480 61999"> 99480 61999,</a>

                                    <a className="num-rell-l" href="tel:92912 03306"> 92912 03306,</a>
                                    <br />
                                    <a className="num-rell-l" href="tel:81215 96699">81215 96699,</a>

                                    <a className="num-rell-l" href="tel:94946 86767">94946 86767</a>
                                    <p></p>
                                </div><br/>
                                <div className="contact-page__info-box-address">
                                    <p className="contact-page__info-box-address-text">
                                        <i className="fas fa-envelope px-1"></i>
                                        <a className="a-hr-c" href="mailto:help@kbkwelfareassociation.com">help@kbkwelfareassociation.com</a> <br />
                                        <a className="a-hr-c" href="www.kbkwelfareassociation.com">
                                            <i className="fas fa-globe px-1"></i>

                                            www.kbkwelfareassociation.com
                                        </a>
                                    </p>
                                </div>
                                <br />
                                <div className="contact-page__info-box-follow">
                                    <h4 className="contact-page__info-box-tilte">Follow</h4>
                                    <div className="contact-page__info-box-follow-social">
                                        <a target="_blank" href="https://twitter.com/kbkwelfare"><i className="fab fa-twitter"></i></a>
                                        <a target="_blank" href="https://www.facebook.com/KBK-Welfare-Association-101633825494305" className="clr-fb"><i className="fab fa-facebook-square"></i></a>

                                        <a target="_blank" href="https://www.instagram.com/kbkwelfare/" className="clr-ins"><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
           </section>
           <section className="contact-page-google-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1903.326581732808!2d78.45529074100038!3d17.428424758356204!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf6f47e51347556b4!2sKBK%20Business%20Solutions!5e0!3m2!1sen!2sin!4v1575626451688!5m2!1sen!2sin" width="100%" height="300"  ></iframe>

        </section>
            
            
        </div>
    );
}
    export default Contact;


