import React from 'react'

export default function AboutContent() {
    const customStyles = {
        position: 'relative',
        left: '20px'
      };
    
  return (
    <div>
      <section className="make-donation-two">
        <div className="container">
            <div className="row">
                <div className="col-xl-9 col-lg-9">
                    <div className="make-donation-two__left">
                        <div className="block-title text-left">
                            <h1>About KBK Welfare Association</h1>
                        </div>
                        <p>
                            KBK Welfare Association commenced to work with a mission to foster health care, living and essentials for the impoverished communities residing in the State of Telangana.
                        </p>
                        <p>
                            As an NGO organization, we are passionate about contributing our share to society and doing good.
                        </p>
                        <p>
                            We love to share the responsibility and would like to come up with more plans in the near future as well.
                        </p>
                        <p>
                            For now we have the following activities coming up and we are so glad to welcome young and talented participants to join us and share their services to the communities in the State of Telangana.
                        </p>

                        <p></p>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                    <div className="make-donation-two__right">
                        <div className="row">
                            <div className="col-xl-11 col-lg-11">
                                <div className="make-donation-two__single-img">
                                    <img src="images/about-banner.jpg" alt="Orphans welfare" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <p>
                        In the pandemic period, we have seen many have been affected, especially those who are below the poverty line who include daily wage workers, slum areas people, in orphan homes and old age homes.
                    </p>
                    <p>
                        Having witnessed the hard-bearing pain and suffering that people went through especially poor communities,KBK Welfare Association took the mission to a step forward in offering a helpline in the best manner.
                    </p>
                    <p>
                        KBK Welfare Association began its mission work much earlier, wherein we helped people living in flooded locations with food distribution and also contributed finances to the performance of a wedding in the community.
                    </p>

                    <p></p>
                </div>
            </div>
        </div>
      </section>

      <section className="">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="make-donation-two__left">
                            <div className="block-title text-left">

                               <h3> They felt very happy and shared their joy.  </h3>
                            </div>
                            <p>

                                Continuing our support in the long span of the pandemic period, we are helping daily wage workers and other manual labor workforce, who experienced heavy financial difficulties.
                            </p><p>
                                KBK Welfare Association  has commissioned its work by distributing of groceries, vegetables, blankets, raincoats, food, clothing, study materials, facemasks, sanitizers and vitamin tablets to children in orphan homes, old age homes and various needy communities.

                            </p>






                            <p></p>

                        </div>
                    </div>
                    </div>
            </div>
      </section>

      <section className="">
        <div className="container">
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="block-title text-left">
                        <h3>Let us do good.</h3>
                    </div>
                    <ul className="list-unstyled become-volunteer__requirements-list">
                        <li>
                            <div className="become-volunteer__requirements-icon">
                                    <i className="fa-regular fa-circle-check fa-xl" style={{ color: '#e2a34b' }}></i>
                            </div>

                            <p style={customStyles}>We are getting deeply involved in the distribution of groceries, vegetables, and food.</p>
                        </li>
                        <li>
                            <div className="become-volunteer__requirements-icon">
                                    <i className="fa-regular fa-circle-check fa-xl" style={{ color: '#e2a34b' }}></i>
                            </div>

                            <p style={customStyles}>
                                The hygiene practice of face masks and sanitization being is very important in the pandemic period, we are bringing awareness by distributing face masks, hand sanitizers, and vitamin tablets to various families in
                                different locations.
                            </p>
                        </li>
                        <li>
                            <div className="become-volunteer__requirements-icon">
                                    <i className="fa-regular fa-circle-check fa-xl" style={{ color: '#e2a34b' }}></i>
                            </div>

                            <p style={customStyles}>
                                To children who are attending schools and are now under lockdown, some of them do not have their study materials. To such children, we are distributing school books, bags, stationery and other study materials to
                                orphan homes and organizations.
                            </p>
                        </li>
                        <li>
                            <div className="become-volunteer__requirements-icon">
                                    <i className="fa-regular fa-circle-check fa-xl" style={{ color: '#e2a34b' }}></i>
                            </div>

                            <p style={customStyles}>We are actively visiting old age homes, gifting them clothing and food.</p>
                        </li>
                    </ul>
                </div>

                <div className="col-lg-12">
                    <p>
                        KBK Founder Dr. K Bharath Kumar is a man of philosophy with a passion for working for those who are in need.
                    </p>
                    <p>
                        Coming from a humble background, Dr. K Bharath Kumar has seen the acute suffering of many people within his community and worked very hard to succeed in life.
                    </p>
                    <p>
                        Today, he is not only a successful IT professional, but also an ITpreneur, in Hyderabad, in the State of Telangana, creating scope for employment for others and binds the teams as one family.
                    </p>

                    <p>
                        This is Dr. K Bharath Kumar unique nature of serving others.
                    </p>
                    <p>
                        Thinking in the same line, Dr. K Bharath Kumar has commencedKBK Welfare Association with a view to expand his charity and community services, by creating various segments of work.
                    </p>
                    <br />
                    <ul className="list-unstyled become-volunteer__requirements-list">
                        <li>
                            <div className="become-volunteer__requirements-icon">
                                    <i className="fa-regular fa-circle-check fa-xl" style={{ color: '#e2a34b' }}></i>
                            </div>

                            <p style={customStyles}>Vision</p>
                            <br />
                        </li>

                        <p>
                            KBK welfare association works with a vision to come together to provide
                        </p>
                        <br />
                    </ul>
                </div>
            </div>
        </div>
    </section>


    </div>
  )
}
