import React, { useEffect } from 'react';
import { Container, Card, Carousel, } from 'react-bootstrap';
import $ from 'jquery'; // Import jQuery (ensure it's installed)


const NewsSlider = () => {
  useEffect(() => {
    // Handling the sliding of one image at a time
    $('.carousel').each(function () {
      const minPerSlide = 1;
      const next = $(this).next();
      if (!next.length) {
        $(this).siblings(':first-child');
      }
      next.children(':first-child').clone().appendTo($(this));

      for (let i = 0; i < minPerSlide; i++) {
        next.next().children(':first-child').clone().appendTo($(this));
      }
    });
  }, []);

  const cardItems = [
    {
        text: 'RAIN FLOOD',
        image: '/images/01.jpg',
      },
    { text: 'SCHOOL FEE ', 
      image: '/images/05.jpg',
     },
    { text: 'SCHOOL CHILDRENS  BOOKS... etc', 
    image: '/images/09.jpg',
    },
    { text: 'COVID TIME FOOD DISTRIBUTION', 
    image: '/images/28.jpg',
   },
  ];

  const chunkArray = (arr, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const chunkedItems = chunkArray(cardItems, 4);

  return (
    <div>
      <Container className='py-5'>
        <div className='block-title'>
            <h4>Help the People</h4>
            <h2 >Latest News</h2>
        </div>
        
        <Carousel interval={4000} pause={false} indicators={false} className="carousel slide">
          {chunkedItems.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="row">
                {chunk.map((item, itemIndex) => (
                  <div className="col-md-3" key={itemIndex}>
                    <Card className="mb-3">
                      <Card.Img variant="top" src={item.image} alt={`Slide ${index * 4 + itemIndex + 1}`} />
                      <Card.Body>
                        <Card.Title>{item.text}</Card.Title>

                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default NewsSlider;
