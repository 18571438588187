import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const AboutBanner = () => {
  return (
    <>
      <Helmet>
      <title>Non Government Organization In Hyderabad | KBK Welfare</title>

    <meta name="Description" content="KBK welfare asssociation is one of the top ngo in hyderabad is mission to help in health care, living and essentials for needy around hyderabad."/>

    <meta name="Keywords" content="Welfare Associations in Hyderabad, KBK Welfare Association in Hyderabad, Famous NGOs in Hyderabad, Food Donation, Grocery Donation, Clothes Donation, NGO organizations"/>

    <meta http-equiv="cleartype" content="on"/>

    <meta name="author" content="Kbkwelfare"/>

    <meta name=" https://kbkwelfareassociation.com/index.html" CONTENT=" Food Donation , Grocery Donation"/>

    <meta name="rating" CONTENT="General"/>

    <meta name="robots" content="index,follow,noarchive"/>

    <meta name="revisit-after" CONTENT="daily"/>
	
    <meta content="English" name="language"/>


    <meta property="og:type" content="website"/>

    <meta property="og:locale" content="en_US"/>

    <meta property="og:title" content="KBK Welfare Association in hyderabad | NGO in Hyderabad "/>

    <meta property="og:description" content="KBK welfare asssociation is one of the top ngo in hyderabad is mission to help in health care, living and essentials for needy around hyd."/>

    <meta property="og:url" content="https://kbkwelfareassociation.com/index.html"/>

    <meta property="og:site_name" content="KBK Welfare Association in hyderabad | NGO in Hyderabad "/>
      </Helmet>
      <section
        className="page-header"
        style={{
          backgroundImage: 'url(https://kbkwelfareassociation.com/assets/images/resources/aboutus.jpg)',
        }}
      >
        <div className="container">
          <div className="page-header__inner">
            <h2>About</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="https://kbkwelfareassociation.com/">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>About</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
