import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div>
      <footer className='py-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <div className="footer-widget__column footer-widget__about">
                        <div className="footer-widget__logo">
                            <a href="index"><img src="/images/logo.webp" alt="KBK welfare association logo"/></a>
                        </div>
                        <p className="footer-widget__text">

                            KBK Welfare Association commenced to work with a mission to foster health care, living and essentials for the impoverished communities.


                        </p>
                        <div className="site-footer__social">

                            <a target="_blank" href="https://www.facebook.com/KBK-Welfare-Association-101633825494305" className="clr-fb"><i className="fab fa-facebook-square"></i></a>
                            <a target="_blank" href="https://twitter.com/kbkwelfare"><i className="fab fa-twitter"></i></a>
                            <a target="_blank" href="https://www.instagram.com/kbkwelfare/" className="clr-ins"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                   <div className="footer-widget__column footer-widget__explore clearfix">
                        <h3 className="footer-widget__title">Quick Links</h3>
                        <ul className="footer-widget__explore-list list-unstyled">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="about">About Us</Link></li>
                            <li><Link to="food-distribution">Our Work</Link></li>
                            <li><Link to="gallery">Events Gallery</Link></li>
                            <li><Link to="news">News</Link></li>
                            <li><Link to="partner-with-us">Partner With Us</Link></li>
                            <li><Link to="contact">Contact Us</Link></li>
                        </ul>

                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="footer-widget__column footer-widget__contact">
                    <h3 className="footer-widget__title ml-3">Contact</h3>
                    <ul className="list-unstyled footer-widget__contact-list">
                        <li>
                            <div className="icon">
                                <i className="fas fa-phone-square-alt"></i>
                            </div>
                            <div className="text">
                                <p>
                                    <a href="tel:99480 61999">99480 61999,</a>
                                    <a href="tel:92912 03306">92912 03306,</a><br/>
                                    <a href="tel:81215 96699">81215 96699,</a>
                                    <a href="tel:94946 86767">94946 86767</a>
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="icon">
                                <i className="fas fa-envelope"></i>
                            </div>
                            <div className="text">
                                <p><a href="mailto:help@kbkwelfareassociation.com">help@kbkwelfareassociation.com</a></p>
                            </div>
                        </li>
                        <li>
                            <div className="icon">
                                <i className="fas fa-map-marker-alt"></i>
                            </div>
                            <div className="text">
                                <p>
                                    <a href='https://maps.app.goo.gl/YmenQhpzRfF3gQWy5'>H-No:2-1-8/4/1/NR, Suite 2D, Saraswathi Colony, <br />
                                    Uppal, Hyd-39, TS, India</a>
                                </p>
                            </div>
                        </li>
                    </ul>
                    </div>

                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="frt">
                        <h3 className="footer-widget__title">Support</h3>
                        <div>
                            <ul className="footer-widget__explore-list list-unstyled scr-r">
                                <li><Link to="food-distribution">Food Distributions</Link></li>
                                <li><Link to="grocery"> Grocery Distribution </Link></li>
                                <li><Link to="school-stationary">School Stationary</Link></li>
                                <li><Link to="cloth-distribution">Cloth Distribution</Link></li>
                                <li><Link to="covid-19">Covid – 19 Essentials </Link></li>
                            </ul>
                        </div>

                        <ul className="footer-widget__explore-list list-unstyled scr-r">
                            <li className="srp-is">
                                Your support is much appreciated as we work unitedly for the well-being of the society.
                            </li>
                            <li className="rd-pdd">
                                <div className="footer-widget__support-btn">
                                    <Link to="contact"><i className="fas fa-heart"></i>Donate</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </footer>
      <div className="site-footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="site-footer-bottom__inner">
                            <div className="site-footer-bottom__left">
                                <p>© 2021 KBKBWelfareassociation. All rights reserved.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
