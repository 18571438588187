import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function FoodDistribution() {
  return (
    <div>
      <Helmet>
      <title>Food Distribution to Orphanas | Food Donation in Hyderabad</title>

        <meta name="Description" content="KBK welfare association is an ngo to help the feed the needy people in hyderabad. We donate food to help make a difference with food donation in hyderabad."/>

        <meta name="Keywords" content="Food Donation, Food Donation in Hyderabad, Food Distribution to Needy, Food Donation Organisations, Giving Food to the Needy, KBK Welfare"/>

        <meta http-equiv="cleartype" content="on"/>

        <meta name="author" content="Kbkwelfare"/>

        <meta name=" https://kbkwelfareassociation.com/food-distribution.html" CONTENT="Food Donation, Food Distribution to needy"/>

        <meta name="rating" CONTENT="General"/>

        <meta name="robots" content="index,follow,noarchive"/>

        <meta name="revisit-after" CONTENT="daily"/>

        <meta content="English" name="language"/>


        <meta property="og:type" content="website"/>

        <meta property="og:locale" content="en_US"/>

        <meta property="og:title" content="Food Distribution to Orphanas | Food Donation in Hyderabad"/>

        <meta property="og:description" content="KBK welfare association is an ngo to help the feed the needy people in hyderabad. We donate food to help make a difference with food donation in hyderabad."/>

        <meta property="og:url" content="https://kbkwelfareassociation.com/food-distribution.html"/>
      </Helmet>
      <section className="page-header" style={{ backgroundImage: "url(/images/gallerybanner.jpg)" }}>
      <div className="container">
        <div className="page-header__inner">
          <h2>Food Distribution</h2>
          <ul className="thm-breadcrumb list-unstyled">
            <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
            <li><span>/</span></li>
            <li>Food Distribution</li>
          </ul>
        </div>
      </div>
      </section>
      <section className="make-donation-two">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="make-donation-two__left">
                        <div className="block-title text-left">
                            <h1>Food Distribution</h1>
                        </div>
                        <p className="make-donation-two__text">
                            We saw some emergency situations in the Telangana region back in 2020. There was a lot of flooding. Many of the locations were completely drowned and many homes were swept away in rain floods.
                            <br />
                            At that time, we saw some locals who were daily wage workers, waiting for food with their women and children.
                            <br />
                            We went ahead and distributed food and drinking water.
                            <br />
                            We plan to continue to do this work as it is much required, especially for low-income groups who sometimes do not find daily work and may have to wait until their new job.
                            <br />
                            We stay connected with them and periodically visit them for <a href="https://kbkwelfareassociation.com/gallery.html">food distribution. </a>
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="make-donation-two__right">
                        <div className="make-donation-two__single-img">
                            <img src="/images/food.webp" alt="Food Donation" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

     
    </div>
  )
}
