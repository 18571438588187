import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';


export default function PartnerWithUs() {
  return (
    <div>
        <Helmet>
        <title>KBK Welfare Association | Partner With Us</title>

        <meta name="Description" content="KBK welfare association as a ngo performs welfare activities for needy people in hyderabad. We are in need of volunteers as our work is growing rapidly."/>

        <meta name="Keywords" content="KBK Welfare Association, NGO Organizations, Food Donation, Grocery Donation, Clothes Donation, KBK Welfare Work, Famous NGOs in Hyderabad"/>

        <meta http-equiv="cleartype" content="on"/>

        <meta name="author" content="Kbkwelfare"/>

        <meta name=" https://kbkwelfareassociation.com/partner-with-us.html" CONTENT="KBK WELFARE work, Welfare Associations in Hyderabad"/>

        <meta name="rating" CONTENT="General"/>

        <meta name="robots" content="index,follow,noarchive"/>

        <meta name="revisit-after" CONTENT="daily"/>

        <meta content="English" name="language"/>

        <meta property="og:type" content="website"/>

        <meta property="og:locale" content="en_US"/>

        <meta property="og:title " content="KBK Welfare Association | Partner With Us"/>

        <meta property="og:description" content="KBK welfare association as a ngo performs welfare activities for needy people in hyderabad. We are in need of volunteers as our work is growing rapidly."/>

        <meta property="og:url" content="https://kbkwelfareassociation.com/partner-with-us.html"/>

        <meta property="og:site_name" content="KBK Welfare Association | Partner With Us"/>
        </Helmet>
      <section className="page-header" style={{ backgroundImage: "url(/images/aboutus2.jpg)"}} >
            <div className="container">
                <div className="page-header__inner">
                    <h2>Partner With Us</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li>Partner With Us</li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="testimonials-one">
            <br />
            <div className="testimonials-one__container-box">
                <div className="block-title text-center">
                    <h1>Partner With Us</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="list-unstyled become-volunteer__requirements-list">
                                <li>
                                    <div className="become-volunteer__requirements-icon"></div>
                                    <div className="become-volunteer__requirements-text">
                                        <p>
                                            Partnering with <a href="https://kbkwelfareassociation.com/">KBK Welfare Association </a>has been very good. They are very hard-working and conduct some of the best programs for the poor communities in
                                            the Telangana region. So glad, we are connected with KBK.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="become-volunteer__requirements-icon"></div>
                                    <div className="become-volunteer__requirements-text">
                                        <p>We have been associated with KBK for the past five years and we have always found their association and their charity programs are full of purpose. We are very excited about their upcoming works.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}
