import React from 'react'
import {Helmet} from "react-helmet"
import { Link } from 'react-router-dom';

export default function FoodDistributionNacharam() {
  return (
    <div>
        <Helmet>
        <title> Food Distribution Event at Sadhana Institute in Nacharam</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Food Distribution Events</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Food Distribution </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Food Distribution Event at Sadhana Institute in Nacharam</h2>
                            <p className='text-dark'>On the occasion of KBK Group Chairman and CEO Dr. Kakkireni Bharath Kumar and Jaya Vyshnavi's Daughter Baby Bhavysri Kshitija's birthday on Tuesday, July 23rd, 2024, the KBK Welfare Association, a unit of the KBK Group, sponsored a breakfast for intellectually challenged people at Sadhana Institute in Nacharam, Hyderabad.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/1.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/1.webp" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/2.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/2.webp" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/3.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/3.webp" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/4.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/4.webp" alt="Img"/></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/5.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/5.webp" alt="Img"/></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/6.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/6.webp" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/8.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/8.webp" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/9.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/9.webp" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/10.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/10.webp" alt="Img" /></a>
                    </div>
                    
                    
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
