import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function Covid19Essentials() {
  return (
    <div>
        <Helmet>
        <title>Covid-19 Essentials Donation in Hyderabad | KBK Welfare</title>

        <meta name="Description" content="KBK welfare association in Hyderabad. Visited many regions distributing sanitizers, Face Masks, and other covid-19 essentials."/>

        <meta name="Keywords" content="KBK Welfare Association, NGO Organizations, Grocery Donation, KBK Welfare Work, Famous NGOs in Hyderabad"/>

        <meta http-equiv="cleartype" content="on"/> 

        <meta name="author" content="Kbkwelfare"/>

        <meta name=" https://kbkwelfareassociation.com/covid-19.html" CONTENT="Famous NGOs in Hyderabad,  Welfare Associations in Hyderabad"/>

        <meta name="rating" CONTENT="General"/>

        <meta name="robots" content="index,follow,noarchive"/>

        <meta name="revisit-after" CONTENT="daily"/>

        <meta content="English" name="language"/>

        <meta property="og:type" content="website"/>

        <meta property="og:locale" content="en_US"/>

        <meta property="og:title " content="Covid-19 Essentials Donation in Hyderabad | KBK Welfare"/>

        <meta property="og:description" content="KBK welfare association in Hyderabad. Visited many regions distributing sanitizers, Face Masks and other covid-19 essentials."/>

        <meta property="og:url" content="https://kbkwelfareassociation.com/covid-19.html"/>

        <meta property="og:site_name" content="Covid-19 Essentials Donation in Hyderabad | KBK Welfare" />
        </Helmet>
      <section className="page-header" style={{ backgroundImage: "url(/images/new/covid-19.jpg)"}} >
            <div className="container">
                <div className="page-header__inner">
                    <h2>  Covid - 19 Essentials  </h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li>  Covid - 19 Essentials   </li>
                    </ul>
                </div>
            </div>
      </section>
      <section className="make-donation-two">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="make-donation-two__left">
                        <div className="block-title text-left">
                            <h1>Covid - 19 Essentials</h1>
                        </div>
                        <p classtincidunt="make-donation-two__text">
                            <a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html"> Covid-19 </a>has had a huge impact on the lives of people, especially those who are underprivileged. Particularly, when the Government was
                            emphasizing the use of sanitizer, face mask and washing of hands, we noticed that some people did not know what is Covid-19 and its essentials.

                            <br />
                            We took this activity massively in the year 2020 and visited many regions distributing sanitizers, face masks and hand washing liquid and soaps explaining to people how to follow social distancing.
                            <br />
                            We also plan to continue this work with rapid distribution of Covid-19 essentials and with the help of <a href="https://kbkwelfareassociation.com/">volunteers,</a> we would like to bring awareness and the importance
                            of hygiene practice among the locals in the perspective of health and well-being.
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="make-donation-two__right">
                        <div className="make-donation-two__single-img">
                            <img src="/images/covid.jpg" alt="covid19 essential donation" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}
