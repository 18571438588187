import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';


export default function AmmaNanna() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> AMMA NANNA ANADHA ASHRAMAM </h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> AMMA NANNA ANADHA ASHRAMAM  </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">AMMA NANNA ANADHA ASHRAMAM</h2>
                            <p className='text-dark'>KBK Welfare Association Offered Free Meals to the Mentally Challenged at Amma Nanna Ashramam. </p>
                            <p className='text-dark'>The KBK Welfare Association served a complimentary supper to 400 mentally challenged people at the Amma Nanna Ashramam in Choutuppal. This endeavour was held to commemorate the birthday of Dr. Kakkireni Bharath Kumar, Founder and Philanthropist of the KBK Group.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-01.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-01.jpg" alt="Food Distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-02.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-02.jpg" alt="Feed the needy programme" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-03.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-03.jpg" alt="birthday celebration" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-04.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-04.jpg" alt="food donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-05.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-05.jpg" alt="old age welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-06.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-06.jpg" alt="food distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-07.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-07.jpg" alt="oldage home" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-08.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-08.jpg" alt="food donation ngo" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-09.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-09.jpg" alt="old people welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-10.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-10.jpg" alt="welfare for disabled" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-11.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-11.jpg" alt="old age welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-12.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-12.jpg" alt="food for child welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-13.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-13.jpg" alt="kbk welfare food donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-14.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-14.jpg" alt="food distribution program" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-15.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-15.jpg" alt="Food for the hungry" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-16.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/dp-16.jpg" alt="Feed the needy programme" /></a>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
