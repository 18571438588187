import React from 'react';
import {Helmet} from "react-helmet"
import { Link } from 'react-router-dom';

export default function Covid() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Covid Time  Food Distribution</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Covid Time Food Distribution</li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Covid Time Food Distribution</h2>
                            <p className='text-dark'>During the COVID-19 pandemic, the KBK Welfare Association distributed food to migrant workers.</p>
                            <p className='text-dark'>During the COVID-19 pandemic, which tragically claimed the lives of several lakh individuals worldwide, many migrant workers in our country faced hunger as a result of the enforced lockdowns. KBK Welfare Society extended support to migrant workers in Hyderabad, providing food to them and the impoverished in the city's Basti area for approximately 20 days.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/22.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/22.jpg" alt="Food donation for orphans" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/26.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/26.jpg" alt="Food Donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/30.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/30.jpg" alt="Food Donation for orphan welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/31.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/31.jpg" alt="Food Donation" /></a>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
