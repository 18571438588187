import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';


export default function LalanaFoundation() {
  return (
    <div>
        <Helmet>
        <title> Gallery || KBK WELFARE ASSOCIATION</title>
        </Helmet>
        <section className="page-header" style={{backgroundImage: "url(/images/gallerybanner.jpg)"}}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Lalana Foundation</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Lalana Foundation </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Food Donation Program at the Lalana Foundation</h2>
                            <p className='text-dark'>KBK Welfare Association Organized Food Distribution in the Lalana Foundation
                               The KBK Welfare Association, a division of the KBK Group, organized a food donation program at the Lalana Foundation in Nagole. This event celebrated the birthday of Bhavysree Kshitija, the daughter of KBK Group CEO K Bharat Kumar and Jaya Vaishnavi. Following the occasion, the Ashram's students received free pens.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/7.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/7.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/9.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/9.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/10.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/10.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/11.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/11.jpg" alt="Img"/></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/12.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/12.jpg" alt="Img"/></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/14.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/14.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/23.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/23.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/25.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/25.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/26.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/26.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/27.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/27.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/30.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/30.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/32.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/32.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/34.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/34.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/40.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/40.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/47.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/47.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/48.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/48.jpg" alt="Img" /></a>
                    </div>
                    
                </div>
                <br />
                <br />
            </div>
        </div>

    </div>
  )
}
