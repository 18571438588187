import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function SchoolStationary() {
  return (
    <div>
        <Helmet>
        <title>School Stationary Donation in Hyderabad | Free Books Distribution</title>

        <meta name="Description" content="KBK welfare association started this initiative for the free supply of education books, Stationary and other requirements to help children."/>

        <meta name="Keywords" content="NGOs in Hyderabad, Welfare Associations in Hyderabad, KBK Welfare Work, NGO Organizations, Non-Government Organizations in Hyderabad"/>

        <meta http-equiv="cleartype" content="on"/>

        <meta name="author" content="Kbkwelfare"/>

        <meta name=" https://kbkwelfareassociation.com/school-stationary.html" CONTENT="NGOs in Hyderabad for Eduction,  Welfare Associations in Hyderabad"/>

        <meta name="rating" CONTENT="General"/>

        <meta name="robots" content="index,follow,noarchive"/>

        <meta name="revisit-after" CONTENT="daily"/>

        <meta content="English" name="language"/>


        <meta property="og:type" content="website"/>

        <meta property="og:locale" content="en_US"/>

        <meta property="og:title " content="School Stationary Donation in Hyderabad | Free books distribution" />

        <meta property="og:description" content="KBK welfare association started this initiative for the free supply of education books, stationery and other requirements to help children."/>

        <meta property="og:url" content="https://kbkwelfareassociation.com/school-stationary.html"/>

        <meta property="og:site_name" content="School Stationary Donation in Hyderabad | Free Books Distribution" />
        </Helmet>
        <section className="page-header" style={{ backgroundImage: "url(/images/new/stationery.jpg)" }}>
        <div className="container">
            <div className="page-header__inner">
            <h2> School Stationery </h2>
            <ul className="thm-breadcrumb list-unstyled">
                <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                <li><span>/</span></li>
                <li> School Stationery </li>
            </ul>
            </div>
        </div>
        </section>
        <section className="make-donation-two">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="make-donation-two__left">
                            <div className="block-title text-left">
                                <h1>School Stationery</h1>
                            </div>
                            <p className="make-donation-two__text">
                                There is a huge population of young children who have the desire to go to school and parents cannot afford to pay school fees and they remain uneducated.
                                <br />
                                This is one of the growing causes of illiteracy in the region and although there are many NGO organizations and education homes providing free primary education to young children, their resources are very limited.
                                <br />
                                Due to this, they lack funds to purchase books and other learning materials to provide to children.
                                <br />

                                <a href="https://kbkwelfareassociation.com/">KBK welfare association</a> started this initiative for the free supply of education books, stationery and other requirements to
                                <a href="https://kbkwelfareassociation.com/gallery.html"> help children.</a>
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="make-donation-two__right">
                            <div className="make-donation-two__single-img">
                                <img src="/images/bok.jpg" alt="School stationary donation" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}
