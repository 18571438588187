import React, { useEffect } from 'react';
import { Container, Card, Carousel, Button } from 'react-bootstrap';
import $ from 'jquery'; // Import jQuery (ensure it's installed)


const WhatTheySay = () => {
  useEffect(() => {
    // Handling the sliding of one image at a time
    $('.carousel').each(function () {
      const minPerSlide = 1;
      const next = $(this).next();
      if (!next.length) {
        $(this).siblings(':first-child');
      }
      next.children(':first-child').clone().appendTo($(this));

      for (let i = 0; i < minPerSlide; i++) {
        next.next().children(':first-child').clone().appendTo($(this));
      }
    });
  }, []);

  const cardItems = [
    {
        paragraph: "I lost everything in the floods in May 2020 when the Telangana region suffered mostly and I had no hope on how I could help my family. In this desperate situation, KBK Welfare Association Founder Dr. K Bharath Kumar garu helped me financially to sustain the loss and damage. It was a great help in my need. Thanks to the KBK Welfare Association.",
        paragraph1: "Suresh Korapati- May 2020"
      },
    { 
      paragraph: "At the time of Covid-19, KBK Welfare Association visited many locations in Telangana where poor people were looking for food. There were hundreds of people who were hungry. They had no food and no water. We supplied them with enough food and clean drinking water. They were very happy.",
      paragraph1: "KBK Founder - Dr. K Bharath Kumar May 2020"
     },
    {
    paragraph: "In Covid-19 period, there was a total lockdown and we lost our daily wage jobs. We did not know what to do. At that time, KBK Welfare Association Founder Dr. K Bharath Kumar garu and Arun came to our rescue with the distribution of face masks, sanitizers and food. It was a great help. We are always thankful to the KBK Welfare Association. ",
    paragraph1: "Community people - Telangana Region, June 2020"
    },
    { 
    paragraph: "Children were in need of books and writing materials. But we could not buy it due to Covid-19 restrictions and also lack of funds.  Fortunately, Dr. K Bharath Kumar donated school books and our welfare organization children were so happy to receive a set of new books. We are very Thankful to the KBK Welfare Association.",
    paragraph1: "Bhaskar Sai Balaji Welfare Organization - 24 September 2020"
   },
    { 
     paragraph: "My sister was about to get married in September 2020. But I was running short of marriage expenses and I needed help. I’m deeply grateful to the KBK welfare association as they have donated funds to perform my sister’s marriage. I performed my sister’s marriage successfully and it was a very good occasion. I’m always thankful to Dr. K Bharath Kumar garu.",
     paragraph1: "Anil Kumar, 25 February, 2020"
    },
    {
       
        paragraph: "Paying the school fee was necessary and I had no funds to make the payment in the month of February 2021. The KBK welfare association came to offer their financial help and they paid the school fee for our children. We are very thankful to Dr. K Bharath Kumar, who has such a great helping heart. Thanks to the KBK Welfare Association.",
        paragraph1: "Saritha Vidya Niket, 14 February 2021"
      },
  ];

  const chunkArray = (arr, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const chunkedItems = chunkArray(cardItems, 1);

  return (
    <div>
      <Container className='py-5'>
        <div className='block-title text-center'>
            <h4 className='text-center'>Happy People</h4>
            <h2 className='text-center'>What They Say</h2>
        </div>
        
        <Carousel interval={4000} pause={false} indicators={false} className="carousel slide">
          {chunkedItems.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="row">
                {chunk.map((item, itemIndex) => (
                  <div className="col-md-12 mb-4" key={itemIndex}>
                    <Card className="mb-3 shadow">
                    <div className="testimonials-one__quote">
                        <img src="images/new/testimonials-one-icon-1.png" alt="icon"/>
                    </div>
                      <Card.Body>
                        <Card.Text>{item.paragraph}</Card.Text>
                        <Card.Text>{item.paragraph1}</Card.Text>  

                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default WhatTheySay;
