import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function EventsGallery() {
  return (
    <div>
        <Helmet>
        <title>Non government organizations in Hyderabad | KBK Welfare Gallery</title>

        <meta name="Description" content="KBK welfare asssociation is one of the top welfare associations in hyderabad is mission to feed the needy people around hyderabad."/>

        <meta name="Keywords" content="KBK Welfare Association, NGO Organizations, Food Donation, Grocery Donation, KBK Welfare work, Famous NGOs in Hyderabad"/>

        <meta http-equiv="cleartype" content="on"/>

        <meta name="author" content="Kbkwelfare"/>

        <meta name=" https://kbkwelfareassociation.com/gallery.html" CONTENT="NGO Organizations, Famous NGOs in Hyderabad"/>

        <meta name="rating" CONTENT="General"/>

        <meta name="robots" content="index,follow,noarchive"/>

        <meta name="revisit-after" CONTENT="daily"/>

        <meta content="English" name="language"/>



        <meta property="og:type" content="website"/>

        <meta property="og:locale" content="en_US"/>

        <meta property="og:title " content="Non Government Organizations in Hyderabad | KBK Welfare Gallery"/>

        <meta property="og:description" content="KBK welfare asssociation is one of the top welfare associations in hyderabad is mission to feed the needy people around hyderabad."/>

        <meta property="og:url" content="https://kbkwelfareassociation.com/gallery.html"/>

        <meta property="og:site_name" content="Non Government Organizations in Hyderabad | KBK Welfare Gallery"/>
        </Helmet>
        <section className="page-header" style={{ backgroundImage: "url(/images/new/gallery.jpg)" }}>
            <div className="container">
                <div className="page-header__inner">
                    <h2> Events Gallery </h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="https://kbkwelfareassociation.com/">Home</Link></li>
                        <li><span>/</span></li>
                        <li> Events Gallery  </li>
                    </ul>
                </div>
            </div>
        </section>

        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Food Distribution Event at Sadhana Institute in Nacharam</h2>
                            
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/1.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/1.webp" alt="Img" /></a>
                    </div>
                    
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/2.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/2.webp" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/3.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/3.webp" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/sadhana/4.webp" data-lightbox="photos"><img className="img-fluid img-glry" src="images/sadhana/4.webp" alt="Img" /></a>
                    </div>
                    
                
                </div>
                <Link to="/food-distribution-event-at-sadhana-institute-in-nacharam"> <button className="red-m">Read More</button></Link>
            </div>
        </div>

        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">Food Distribution Event at Ashakuteer Orphanage</h2>
                            
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/1.jpg" alt="Img" /></a>
                    </div>
                    
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/2.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/3.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/Ashakuteer-Orphanage/4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/Ashakuteer-Orphanage/4.jpg" alt="Img" /></a>
                    </div>
                    
                
                </div>
                <Link to="/food-distribution-event-at-ashakuteer-orphanage"> <button className="red-m">Read More</button></Link>
            </div>
        </div>

        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">KBK Welfare Association Provided Financial Assistance</h2>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/Mallikarjun2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/Mallikarjun2.jpg" alt="Img" /></a>
                    </div>
                    
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/Yadaiah1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/Yadaiah1.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/Mallikarjun1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/Mallikarjun1.jpg" alt="Img" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/new/Yadaiah2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/Yadaiah2.jpg" alt="Img" /></a>
                    </div>
                    
                
                </div>
                <Link to="/financial-assistance"> <button className="red-m">Read More</button></Link>
            </div>
        </div>
        <div className="lightbox-gallery">
        <div className="container">
            <div className="row photos">
                <div className="col-lg-12">
                    <div className="block-title text-left">
                        <br />
                        <br />

                        <h2 className="ab-k">KBK Multispecialty Hospital organized A Mega Free Medical Camp</h2>
                    </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/new/_KBK8100.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8100.jpg" alt="Img" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/new/_KBK8101.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8101.jpg" alt="Img" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/new/_KBK8209.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8209.jpg" alt="Img" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/new/_KBK8347.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/_KBK8347.jpg" alt="Img" /></a>
                </div>
              
            </div>
            <Link to="/medical-camp"> <button className="red-m">Read More</button></Link>
        </div>
        </div>
        <div className="lightbox-gallery">
        <div className="container">
            <div className="row photos">
                <div className="col-lg-12">
                    <div className="block-title text-left">
                        <br />
                        <br />

                        <h2 className="ab-k">Food Donation Program at the Lalana Foundation</h2>
                    </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/new/7.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/7.jpg" alt="Img" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/new/9.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/9.jpg" alt="Img" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/new/10.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/10.jpg" alt="Img" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/new/11.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/new/11.jpg" alt="Img"/></a>
                </div>
              
            </div>
            <Link to="/lalana-foundation"> <button className="red-m">Read More</button></Link>
        </div>
        </div>
        <div className="lightbox-gallery">
        <div className="container">
            <div className="row photos">
                <div className="col-lg-12">
                    <div className="block-title text-left">
                        <br />
                        <br />

                        <h2 className="ab-k">Amma Nanna Orphanage</h2>
                    </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="images/dp-01.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8368.jpg" alt="Food Distribution" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/dp-02.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8427.jpeg" alt="Feed the needy programme" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/dp-03.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8428.jpeg" alt="birthday celebration" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="images/dp-04.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="images/_KBK8440.jpeg" alt="food donation" /></a>
                </div>
              
            </div>
            <Link to="/amma-nanna-ashramam"> <button className="red-m">Read More</button></Link>
        </div>
        </div>
        <div className="lightbox-gallery">
        <div className="container">
            <div className="row photos">
                <div className="col-lg-12">
                    <div className="block-title text-left">
                        <br />
                        <br />

                        <h2 className="ab-k">Gottimukla Zila Parishad Government School</h2>
                    </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/welfare-edu1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/welfare-edu1.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/welfare-edu2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/welfare-edu2.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/welfare-edu3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/welfare-edu3.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/welfare-edu4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/welfare-edu4.jpg" /></a>
                </div>
            </div>
            <Link to="/gottimukla-zila-parishad-government-school"> <button className="red-m">Read More</button></Link>
        </div>
        </div>
        <div className="lightbox-gallery">
        <div className="container">
            <div className="row photos">
                <div className="col-lg-12">
                    <div className="block-title text-left">
                        <br />
                        <br />

                        <h2 className="ab-k">Safe Charitable Foundation</h2>
                    </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/safe-charity1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/safe-charity1.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/safe-charity2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/safe-charity2.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/safe-charity3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/safe-charity3.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/safe-charity4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/safe-charity4.jpg" /></a>
                </div>
            </div>
            <Link to="/safe-charitable-foundation"> <button className="red-m">Read More</button></Link>
        </div>
        </div>
        <div className="lightbox-gallery">
        <div className="container">
            <div className="row photos">
                <div className="col-lg-12">
                    <div className="block-title text-left">
                        <br />
                        <br />

                        <h1 className="ab-k">AMMAODI ORPHANAGE</h1>
                    </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/me-1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/me-1.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/me-2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/me-2.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/me-3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/me-3.jpg" /></a>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 item">
                    <a href="/images/me-4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/me-4.jpg" /></a>
                </div>
            </div>
            <Link to="/ammaodi-orphanage"> <button className="red-m">Read More</button></Link>
        </div>
        </div>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">AMMA NANNA ANADHA ASHRAMAM</h2>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/dp-01.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/dp-01.jpg" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/dp-02.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/dp-02.jpg" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/dp-03.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/dp-03.jpg" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/dp-04.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/dp-04.jpg" /></a>
                    </div>
                </div>
                <Link to="/amma-nanna-andha-ashramam"> <button className="red-m">Read More</button></Link>
            </div>
        </div>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">ANJANIDEVI CHARITABLE TRUST</h2>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/kr-1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/kr-1.jpg" alt="Orphans welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/kr-2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/kr-2.jpg" alt="School stationary distribution for children" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/kr-3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/kr-3.jpg" alt="School stationary distribution for children" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/kr-4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/kr-4.jpg" alt="Cultural activities" /></a>
                    </div>
                </div>
                <Link to="/anjanidevi-charitable-trust"> <button className="red-m">Read More</button></Link>
            </div>
        </div>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">PYRAM VIJAYA BARATHI VIDYA SAGAR CHARITABLE TRUST</h2>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/dd-1.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/dd-1.jpg" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/dd-2.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/dd-2.jpg" alt="Food Distribution" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/dd-3.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/dd-3.jpg" alt="Orphan birthday celebration" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/dd-4.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/dd-4.jpg" /></a>
                    </div>
                </div>
                <Link to="/pyramvijayabarathi"> <button className="red-m">Read More</button></Link>
            </div>
        </div>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">RAIN FLOODS</h2>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/01.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/01.jpg" alt="donation for orphanage" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/05.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/05.jpg" alt="welfare program" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/09.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/09.jpg" alt="Charity for needy" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/01.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/01.jpg" alt="donation for orphanage" /></a>
                    </div>
                </div>
                <Link to="/rainfloods"> <button className="red-m">Read More</button></Link>
            </div>
        </div>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">COVID TIME FOOD DISTRIBUTION</h2>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/22.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/22.jpg" alt="Food donation for orphans" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/26.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/26.jpg" alt="Food Donation" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/30.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/30.jpg" alt="Food Donation for orphan welfare" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/31.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/31.jpg" alt="Food Donation" /></a>
                    </div>
                </div>
                <Link to="/covid"> <button className="red-m">Read More</button></Link>
            </div>
        </div>
        <div className="lightbox-gallery">
            <div className="container">
                <div className="row photos">
                    <div className="col-lg-12">
                        <div className="block-title text-left">
                            <br />
                            <br />

                            <h2 className="ab-k">SISTER MARRIAGE</h2>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/38.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/38.jpg" alt="Money Donation for needy" /></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 item">
                        <a href="/images/39.jpg" data-lightbox="photos"><img className="img-fluid img-glry" src="/images/39.jpg" alt="Charity for needy" /></a>
                    </div>
                </div>
                <Link to="/sistermarriage"> <button className="red-m">Read More</button></Link>
            </div>
        </div>









    </div>
  )
}
